import { styled } from '@linaria/react'
import { fetchVendorsListIfNeededAction } from 'actions/authorization/vendorsListAction'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { ChangeLanguageBottomSheet } from 'components/block/ChangeLanguageBottomSheet/ChangeLanguageBottomSheet'
import { CitiesBottomSheet } from 'components/block/CitiesBottomSheet/CitiesBottomSheet'
import { CountriesBottomSheet } from 'components/block/CountriesBottomSheet/CountriesBottomSheet'
import { LoginVariants } from 'components/block/LoginVariants/LoginVariants'
import { OpenVendorAppBottomSheet } from 'components/block/OpenVendorAppBottomSheet/OpenVendorAppBottomSheet'
import {
  BottomSheet,
  useBottomSheet,
} from 'components/designSystem/BottomSheet/BottomSheet'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { NOTIFICATION_REQUIRED_VENDORS } from '../Boarding/SocialNetworks/useVendorAppInstalledNotification'
import { AgreementBottomSheet } from './AgreementBottomSheet'
import { ConfidentialityBottomSheet } from './ConfidentialityBottomSheet'
import { CreateProfileOrLoginBlock } from './CreateProfileOrLoginBlock'
import { HeaderMobile } from './HeaderMobile'
import { LastAuthMethodBlock } from './LastAuthMethodBlock'
import { WelcomePageFooter } from './WelcomePageFooter'
import { WelcomePageTitleBlock } from './WelcomePageTitleBlock'
import { loginPath, selectGenderPath } from './paths'
import { StepCommonProps } from './types'

export const WelcomeStep: FC<StepCommonProps> = ({ active }) => {
  const {
    lastAuthMethod,
    locale,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { lastAuthMethod },
      systemReducer: { locale },
    }) => ({ lastAuthMethod, locale })
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Потому что resetVendorsListAction в resetVendorsListAction при разлогине может отработать раньше
    window.setTimeout(() => {
      dispatch(fetchVendorsListIfNeededAction())
    })
  }, [dispatch])

  const goToCreateProfile = () => {
    dispatch(push(mergeAllUrls(locale, selectGenderPath)))
  }
  const goToLogin = () => {
    dispatch(push(mergeAllUrls(locale, loginPath)))
  }

  const {
    ref: openLoginVariantsBottomSheetRef,
    open: openLoginVariantsBottomSheet,
    close: closeLoginVariantsBottomSheet,
  } = useBottomSheet()
  const {
    ref: openVendorAppBottomSheetRef,
    open: openVendorAppBottomSheet,
  } = useBottomSheet()

  const handleLoginClick = () => {
    dispatch(fetchVendorsListIfNeededAction())
    openLoginVariantsBottomSheet()
  }

  const handleVendorClick = (vendorName: string) => {
    if (
      NOTIFICATION_REQUIRED_VENDORS.includes(vendorName as VendorSocialList)
    ) {
      openVendorAppBottomSheet(vendorName)
    }
  }

  return (
    <WelcomeInner>
      <HeaderMobile visible={active} />

      <WelcomePageTitleBlock welcomeBack={Boolean(lastAuthMethod)} />

      {lastAuthMethod ? (
        <LastAuthMethodBlock
          onLoginClick={handleLoginClick}
          onLoginWithEmail={goToLogin}
          onVendorClick={handleVendorClick}
        />
      ) : (
        <CreateProfileOrLoginBlock
          onLoginClick={handleLoginClick}
          onCreateProfileClick={goToCreateProfile}
        />
      )}

      <WelcomePageFooter />

      <BottomSheet
        ref={openLoginVariantsBottomSheetRef}
        title={<FormattedMessage id="app.enter" defaultMessage="Войти" />}
      >
        <LoginVariants
          inlineVariant={false}
          onLoginWithEmailClick={() => closeLoginVariantsBottomSheet(goToLogin)}
          onVendorClick={(vendorName) =>
            closeLoginVariantsBottomSheet(() => handleVendorClick(vendorName))
          }
          onCreateProfileClick={
            lastAuthMethod
              ? () => closeLoginVariantsBottomSheet(goToCreateProfile)
              : undefined
          }
        />
      </BottomSheet>
      <OpenVendorAppBottomSheet
        noticeId={UniNoticeId.externalMessengerOpenForAuth}
        bottomSheetRef={openVendorAppBottomSheetRef}
      />
      <AgreementBottomSheet />
      <ConfidentialityBottomSheet />
      <CountriesBottomSheet />
      <CitiesBottomSheet />
      <ChangeLanguageBottomSheet />
    </WelcomeInner>
  )
}

const WelcomeInner = styled.div`
  width: 100%;
  height: 100%;
  padding-left: var(--spacing-16px, 16px);
  padding-right: var(--spacing-16px, 16px);

  @media (min-width: ${breakpoints.mobile}px) {
    padding-left: var(--spacing-80px, 80px);
    padding-right: var(--spacing-80px, 80px);
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
`
