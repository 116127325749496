import { styled } from '@linaria/react'
import { VoidHandler } from 'common/types'
import { buttonsBlockCss } from 'components/designSystem/Button/ButtonsBlock'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const CreateProfileOrLoginBlock: FC<{
  onLoginClick: VoidHandler
  onCreateProfileClick: VoidHandler
}> = ({ onLoginClick, onCreateProfileClick }) => {
  return (
    <StyledButtonsBlock>
      <PrimaryButton
        onClick={onCreateProfileClick}
        size="L"
        data-name="create-profile-action"
      >
        <FormattedMessage
          id="app.create.profile"
          defaultMessage="Создать профиль"
        />
      </PrimaryButton>

      <PrimaryButton
        onClick={onLoginClick}
        color="white"
        size="L"
        border={false}
        withPadding
        data-name="login-action"
      >
        <FormattedMessage id="app.enter" defaultMessage="Войти" />
      </PrimaryButton>
    </StyledButtonsBlock>
  )
}

const StyledButtonsBlock = styled.div`
  ${buttonsBlockCss};
  flex-grow: 1;
  padding-top: var(--spacing-12px, 12px);
  @media (min-width: ${breakpoints.mobile}px) {
    padding-top: var(--spacing-24px, 24px);
  }
`
