import { useStepSlider } from 'components/designSystem/StepSlider/StepSlider.hooks'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { FC, memo, useMemo, useRef } from 'react'
import { matchPath } from 'react-router'
import { EnterBirthdayStep } from './EnterBirthdayStep'
import { EnterEmailStep } from './EnterEmailStep'
import { EnterNameStep } from './EnterNameStep'
import { LoginStep } from './LoginStep'
import { RestorePasswordEmailSuccessStep } from './RestorePasswordEmailSuccessStep'
import { RestorePasswordEnterSmsCodeStep } from './RestorePasswordEnterSmsCodeStep'
import { RestorePasswordPhoneSuccessStep } from './RestorePasswordPhoneSuccessStep'
import { RestorePasswordStep } from './RestorePasswordStep'
import { SelectDatingGoalsStep } from './SelectDatingGoalsStep'
import { SelectYourGenderStep } from './SelectYourGenderStep'
import { WelcomeStep } from './WelcomeStep'
import {
  enterBirthdayPath,
  enterNamePath,
  finalStepPath,
  loginPath,
  restorePasswordEmailSuccessPath,
  restorePasswordEnterPhoneCodePath,
  restorePasswordPath,
  restorePasswordPhoneSuccessPath,
  selectDatingPurposePath,
  selectGenderPath,
} from './paths'
import { StepCommonProps } from './types'
import { useLocale } from 'hooks/useLocale'

export const useNavigationSteps = () => {
  const locale = useLocale()
  const lastLoginFlowRef = useRef<boolean | undefined>()

  const { pathname } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => ({ pathname })
  )

  const {
    steps: _steps,
    backgrounds,
    step: _step,
    activeStep,
  } = useMemo(() => {
    const isActiveRoute = (path?: string) => {
      return Boolean(
        matchPath(pathname, {
          path: path ? mergeAllUrls(locale, path) : mergeAllUrls(locale),
          exact: true,
        })
      )
    }

    const indexSteps: StepConfiguration[] = [
      {
        component: WelcomeStepMemoized,
        background: '#ffefe8',
        active: isActiveRoute(),
      },
    ]
    const loginSteps: StepConfiguration[] = [
      {
        component: LoginStepMemoized,
        background: '#ffded0',
        active: isActiveRoute(loginPath),
      },
      {
        component: RestorePasswordStepMemoized,
        background: '#ffded0',
        active: isActiveRoute(restorePasswordPath),
      },
    ]

    if (isActiveRoute(restorePasswordEmailSuccessPath)) {
      loginSteps.push({
        component: RestorePasswordEmailSuccessStepMemoized,
        background: '#ffded0',
        active: isActiveRoute(restorePasswordEmailSuccessPath),
        waveHidden: true,
      })
    }

    if (
      isActiveRoute(restorePasswordEnterPhoneCodePath) ||
      isActiveRoute(restorePasswordPhoneSuccessPath)
    ) {
      loginSteps.push({
        component: RestorePasswordEnterSmsCodeStepMemoized,
        background: '#ffded0',
        active: isActiveRoute(restorePasswordEnterPhoneCodePath),
      })
      loginSteps.push({
        component: RestorePasswordPhoneSuccessStepMemoized,
        background: '#ffded0',
        active: isActiveRoute(restorePasswordPhoneSuccessPath),
        waveHidden: true,
      })
    }

    const onboardingSteps: StepConfiguration[] = [
      {
        component: SelectYourGenderStepMemoized,
        background: '#b3dbff',
        active: isActiveRoute(selectGenderPath),
      },
      {
        component: EnterNameStepMemoized,
        background: '#cebbff',
        active: isActiveRoute(enterNamePath),
      },
      {
        component: EnterBirthdayStepMemoized,
        background: '#ffe179',
        active: isActiveRoute(enterBirthdayPath),
      },
      {
        component: SelectDatingGoalsStepMemoized,
        background: '#fbb9df',
        active: isActiveRoute(selectDatingPurposePath),
      },
      {
        component: EnterEmailStepMemoized,
        background: '#ffded0',
        active: isActiveRoute(finalStepPath),
      },
    ]

    const steps = [...indexSteps]

    const loginStepActive = loginSteps.find((item) => item.active)
    const onboardingStepActive = onboardingSteps.find((item) => item.active)

    if (
      (loginStepActive || lastLoginFlowRef.current === true) &&
      !onboardingStepActive
    ) {
      steps.push(...loginSteps)
      lastLoginFlowRef.current = true
    }
    if (
      (onboardingStepActive || lastLoginFlowRef.current === false) &&
      !loginStepActive
    ) {
      steps.push(...onboardingSteps)
      lastLoginFlowRef.current = false
    }

    const activeStep = steps.find((item) => item.active)
    const stepIndex = activeStep ? steps.indexOf(activeStep) : 0

    return {
      step: stepIndex >= 0 ? stepIndex : 0,
      steps: steps.map(({ component }) => component),
      backgrounds: steps.map(({ background }) => background),
      activeStep,
    }
  }, [locale, pathname])

  const { step, steps } = useStepSlider(_step, _steps)

  return {
    step,
    steps,
    backgrounds,
    loginFlow: lastLoginFlowRef.current,
    activeStep,
  }
}

type StepConfiguration = {
  component: FC<StepCommonProps>
  background: string
  active?: boolean
  waveHidden?: boolean
}

const SelectYourGenderStepMemoized = memo(SelectYourGenderStep)
const EnterNameStepMemoized = memo(EnterNameStep)
const EnterBirthdayStepMemoized = memo(EnterBirthdayStep)
const SelectDatingGoalsStepMemoized = memo(SelectDatingGoalsStep)
const EnterEmailStepMemoized = memo(EnterEmailStep)
const WelcomeStepMemoized = memo(WelcomeStep)
const LoginStepMemoized = memo(LoginStep)
const RestorePasswordStepMemoized = memo(RestorePasswordStep)
const RestorePasswordEmailSuccessStepMemoized = memo(
  RestorePasswordEmailSuccessStep
)
const RestorePasswordEnterSmsCodeStepMemoized = memo(
  RestorePasswordEnterSmsCodeStep
)
const RestorePasswordPhoneSuccessStepMemoized = memo(
  RestorePasswordPhoneSuccessStep
)
