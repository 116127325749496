import React, { SVGProps } from 'react'

export const ChatSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4167 20.1739C17.4333 20.1739 21.5 16.1055 21.5 11.087C21.5 6.06837 17.4333 2 12.4167 2C7.4002 2 3.33348 6.06837 3.33348 11.087C3.33348 12.8703 3.84699 14.5336 4.73414 15.9371C4.2489 17.0488 3.55704 18.3729 2.687 19.5452C2.54466 19.737 2.50773 19.9641 2.5 20.1739C2.50773 20.587 2.83803 21 3.33348 21C3.35669 20.9983 3.55739 20.9766 3.58121 20.9726C5.10922 20.7166 6.7773 20.1936 8.40723 19.243C9.61649 19.8391 10.9775 20.1739 12.4167 20.1739Z"
        fill="#2E2A29"
      />
    </svg>
  )
}
